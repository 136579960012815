/* eslint-disable @typescript-eslint/naming-convention */
import blocks2DocbookFunc, { Block } from '@hmn/blocks2docbook'

import { CombinedSlotProps } from '../../components/Ads/Slot/CombinedSlot.component'
import { ArticleEntityElastic, EntityElastic, ImageEntityElastic, VideoEntityElastic } from '../../types/entity/index'

enum ArticleLength {
    SHORT = 'short',
    MEDIUM = 'medium',
    LONG = 'long'
}

const blocks2Docbook = blocks2DocbookFunc({
    namespace: 'hmn',
    mediaUrlMatchers: {
        rtl: /^https?:\/\/((m|www)\.)?rtl\.hr\/.+/i
    }
})

const parseDocbook = (body: string): Block[] => {
    if (typeof body !== 'string') {
        return []
    }
    return blocks2Docbook.parse(body)
}

type PageBlock = {
    type: 'element'
    name: 'page'
    content: Block[]
}

export const getPagedBlocks = (docbook = '') => {
    const pageProps: PageBlock = {
        type: 'element',
        name: 'page',
        content: []
    }
    const parsedBlocks = parseDocbook(docbook)
    const pagedBlocks = parsedBlocks
        .reduce(
            (all, block) => {
                if (block.name === 'core/nextpage') {
                    all.push({
                        ...pageProps,
                        content: []
                    })
                } else {
                    all[all.length - 1].content.push(block)
                }
                return all
            },
            [
                {
                    ...pageProps,
                    content: []
                }
            ] as PageBlock[]
        )
        .filter(page => page.content.length > 0)

    return pagedBlocks
}

const textMultipler = {
    regular: 1,
    2: 1.3,
    title: 1.5
} as const

const lineLengthMobile = 39
const lineHeightMobile = 32
const paragraphMargin = 11
const titleMargin = 35

const embedHeight = 110

const assumedMobileWidth = 412
const assumedMobilePadding = 30
const assumedContentWidth = assumedMobileWidth - assumedMobilePadding

type EmbedEntities = EntityElastic | ImageEntityElastic | ArticleEntityElastic | VideoEntityElastic
type EmbedItemsMap = Record<string, EmbedEntities>

export const measurePageHeight = (blocks: Block[], embedContentItems: EmbedItemsMap) => {
    const articleBlocks = blocks.filter(block => block.name === 'core/paragraph')
    const articleBlockHeight =
        articleBlocks.reduce((acc, block) => {
            const lines = Math.ceil((block?.attributes?.content?.length || 0) / lineLengthMobile)
            return acc + lines * lineHeightMobile
        }, 0) +
        articleBlocks.length * paragraphMargin
    const headingBlocks = blocks.filter(block => block.name === 'core/heading')
    const headingBlocksHeight =
        headingBlocks.reduce((acc, block) => {
            const lines = Math.ceil(
                (block?.attributes?.content?.length || 0) /
                    (lineLengthMobile / textMultipler[block?.attributes?.level || 'regular'])
            )
            return acc + lines * lineHeightMobile * textMultipler[block?.attributes?.level || 'regular']
        }, 0) +
        titleMargin * headingBlocks.length

    const mapper = (block: Block) => embedContentItems[block.attributes.id] || null
    const imageBlocks = blocks
        .filter(block => block.name === 'hmn/image')
        .map(mapper)
        .filter(Boolean) as ImageEntityElastic[]

    const imageBlockHeight = imageBlocks.reduce((acc, image) => {
        const height = assumedContentWidth / (image.original_aspect_ratio || 1.778)
        return acc + height
    }, 0)
    const videoBlocks = blocks
        .filter(block => block.name === 'hmn/video')
        .map(mapper)
        .filter(Boolean) as VideoEntityElastic[]

    const videoBlockHeight = videoBlocks.reduce((acc, video) => {
        const height = assumedContentWidth / (video.image.original_aspect_ratio || 1.778)
        return acc + height
    }, 0)
    const embedBlocks = blocks.filter(block => block.name === 'core/embed').length * embedHeight

    return {
        height: articleBlockHeight + imageBlockHeight + videoBlockHeight + headingBlocksHeight + embedBlocks,
        numberOfContentBlocks: articleBlocks.length + headingBlocks.length + imageBlocks.length + videoBlocks.length
    }
}

export const measureArticleHeight = (
    pagedBlocks: PageBlock[],
    embedContentItems: EmbedItemsMap,
    pageNumber?: number
) => {
    const measuredPages = pagedBlocks.map(page => measurePageHeight(page.content, embedContentItems))
    const isPaged = typeof pageNumber === 'number'
    if (!isPaged) {
        return {
            height: measuredPages.reduce((acc, page) => acc + (page?.height || 0), 0),
            numberOfContentBlocks: measuredPages.reduce((acc, page) => acc + page.numberOfContentBlocks, 0),
            isPaged
        }
    }
    const pageIndex = pageNumber - 1
    return {
        height: measuredPages[pageIndex].height,
        numberOfContentBlocks: measuredPages[pageIndex].numberOfContentBlocks,
        isPaged
    }
}
const htmlTagsRegex = /<[^>]*>/gim
const getArticleHeadHeight = (article: ArticleEntityElastic) => {
    const headImageHeight = article.image ? assumedMobileWidth / (article.image.original_aspect_ratio || 1.778) : 0
    const headVideoHeight = article.video
        ? assumedMobileWidth / (article.video.image.original_aspect_ratio || 1.778)
        : 0
    const titleHeight =
        Math.ceil((article.title || '').length / lineLengthMobile / textMultipler.title) *
        lineHeightMobile *
        textMultipler.title
    const leadHeight =
        Math.ceil((article.lead?.replace(htmlTagsRegex, '') || '').length / lineLengthMobile) * lineHeightMobile
    return (headVideoHeight || headImageHeight) + leadHeight + titleHeight
}

export type ArticleLengthScore = { score: number; lengthCategory: ArticleLength; debugInfo: any }

export const measureArticleLengthScore = (
    article: ArticleEntityElastic,
    embedContentItems: EmbedItemsMap,
    pageNumber?: number
): ArticleLengthScore => {
    try {
        // const articleContent = article.content
        const blocks = getPagedBlocks(article.body)
        // console.log(
        //     blocks
        //         .map(b => b.content)
        //         .flat()
        //         .map(b => b.name),
        //     article
        // )
        const articleHeightMobile = measureArticleHeight(blocks, embedContentItems, pageNumber)
        const headHeight = getArticleHeadHeight(article)
        // console.log(blocks, embedContentItems)
        // const articleBlocks
        const score = Math.round((articleHeightMobile?.height || 3000) + headHeight)
        const numberOfContentBlocks = articleHeightMobile.numberOfContentBlocks
        const debugInfo = {
            headHeight,
            numberOfContentBlocks,
            isPaged: articleHeightMobile.isPaged
        }

        if (score < 2050 || numberOfContentBlocks < 4) {
            return {
                score,
                lengthCategory: ArticleLength.SHORT,
                debugInfo
            }
        }

        if (score < 4400 || numberOfContentBlocks < 12) {
            return {
                score,
                lengthCategory: ArticleLength.MEDIUM,
                debugInfo
            }
        }

        return {
            score,
            lengthCategory: ArticleLength.LONG,
            debugInfo
        }
    } catch (error: any) {
        // @TODO: handle error
        return {
            score: 0,
            lengthCategory: ArticleLength.LONG,
            debugInfo: {
                hadError: true,
                message: error.message,
                stack: error.stack,
                location: error.location
            }
        }
    }
}

type SingleInsertElementConfig = CombinedSlotProps
type InsertElementConfig = {
    every: number
    offset: number
    afterBlocks: string[]
    insertAtLeast: number
    elements: SingleInsertElementConfig[]
}

export const modifyInsertElementsForShorterArticles = (
    insertElements?: InsertElementConfig,
    lengthScore?: ArticleLengthScore
): InsertElementConfig | undefined => {
    if (!insertElements || !lengthScore) {
        return insertElements
    }
    const isShort = lengthScore.lengthCategory === 'short'
    const isMedium = lengthScore.lengthCategory === 'medium'
    const isLongerMedium = isMedium && lengthScore.score > 3400
    // const isLong = lengthScore.lengthCategory === 'long'
    if (isShort) {
        return undefined
    }
    if (isMedium) {
        return {
            ...insertElements,
            insertAtLeast: 2,
            offset: 0,
            elements: insertElements.elements.map((e, i) => ({
                ...e,
                maxAdHeight: isLongerMedium && i === 0 ? undefined : 400
            }))
        }
    }
    return insertElements
}
